"use client";
import Image from "next/image";
import React, { useEffect, useState } from "react";

const Counter = ({ targetValue, duration, label, icon }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const end = targetValue;
        const totalDuration = duration;
        const incrementTime = (totalDuration / end) > 0 ? (totalDuration / end) : 1; // Ensure no division by zero

        const counterInterval = setInterval(() => {
            start += 1;
            setCount(start);
            if (start === end) {
                clearInterval(counterInterval);
            }
        }, incrementTime);

        return () => clearInterval(counterInterval);
    }, [targetValue, duration]);

    return (
        <div className="p-6">
            <div className="flex justify-center items-center  mb-4">
                <span>
                    <Image
                        src={icon}
                        alt="icon"
                        width={100}
                        height={100}
                        quality={80}	
                        priority={true}
                        className="rounded-full"
                    />
                </span>
            </div>
            <p className="text-center text-2xl font-bold">{count.toLocaleString()}</p>
            <p className="mt-2 text-lg text-gray-500 leading-relaxed text-center">{label}</p>
         
        </div>
    );
};

export default Counter;
