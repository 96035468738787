"use client"; // Ensure this is a client component

import React from "react";
import Footer from "./Footer";
import Navbar from "./navbar";


export default function LandingPageLayout({ children }) {
    return (
        <div>
            <Navbar />
            <main className=" pb-8 bg-flamingo-50">
                {children}
            </main>
            <Footer />
        </div>
    );
}