import(/* webpackMode: "eager" */ "/home/moses-ochacha/code/iwork/client/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/moses-ochacha/code/iwork/client/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/moses-ochacha/code/iwork/client/src/components/landingpage/Achivements.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/moses-ochacha/code/iwork/client/src/components/landingpage/Faqs.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/moses-ochacha/code/iwork/client/src/components/landingpage/Herosection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/moses-ochacha/code/iwork/client/src/components/landingpage/langingpageLayout.js");
