"use client"; // Important for Next.js 13+ to indicate a client component
import React from 'react';
import { FaUserGraduate, FaClipboardCheck, FaProjectDiagram, FaBuilding } from 'react-icons/fa'; // Importing React icons
import Counter from './counter';
import Image from 'next/image';

const achievementsData = [
    {
        icon: "/assets/img/count-icon-01.svg",
        number: 4360,
        label: "Freelance Developers"
    },
    {
        icon: "/assets/img/count-icon-02.svg",
        number: 2847,
        label: "Projects Added"
    },
    {
        icon: "/assets/img/count-icon-03.svg",
        number: 429453,
        label: "Completed Projects"
    },
    {
        icon: "/assets/img/count-icon-04.svg",
        number: 465,
        label: "Companies Registered"
    },
];

export default function Achievements() {
    return (
        <div className='bg-gray-50 w-full overflow-hidden'>
            <>

                <section
                    className="lg:pb-20 lg:pt-20 py-10 relative"
                    id="about"
                    data-aos="zoom out" // Add your desired animation
                >

                    <div className="overflow-hidden">
                        <Image
                            objectFit="cover"
                            fill
                            className="w-full h-full top-0 left-0 object-cover"
                            src="/assets/img/heroImage.webp"
                            alt="Hero Image"
                        />
                        <div className="absolute inset-0 bg-gradient-to-r from-gray-500 to-gray-600 opacity-50"></div>
                    </div>
                    <div className="container mx-auto px-6">
                        <div className="flex justify-center">
                            <div className="lg:w-full text-center relative">
                                <div className="space-y-8 mb-10">
                                    {/* Home Page Title */}
                                    <h2
                                        data-aos="fade-left" // Add your desired animation
                                        data-aos-once="true"
                                        className="text-white tex-xl   md:text-6xl leading-tight capitalize font-bold">
                                        Achievement We Have Earned
                                    </h2>
                                    <p
                                        data-aos="fade-up"
                                        data-aos-once="true"
                                        className='text-sm lg:!text-2xl text-gray-200'>
                                        At Iwork, we believe that talent is borderless and opportunity should be too.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            <div className='relative md:-mt-28 -mt-10 container mx-auto px-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4'>
                {achievementsData.map((achievement, index) => (
                    <div
                        key={index}
                        className="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4"
                    >
                        <Counter targetValue={achievement.number} icon={achievement.icon} duration={2000} label={achievement.label} />
                    </div>
                ))}
            </div>
        </div>
    );
}
