"use client";
import React, { useState, useEffect } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const FAQSection = () => {
    const faqs = [
        {
            question: "How does iWorkFreelance ensure quality freelancers?",
            answer: "iWorkFreelance implements a rigorous vetting process including skill assessments, portfolio reviews, and client feedback systems. We maintain high standards through our rating system and regular performance evaluations."
        },
        {
            question: "What is the payment protection policy?",
            answer: "We offer secure escrow payments for all projects. Funds are only released to freelancers upon client approval, ensuring satisfaction and protecting both parties throughout the transaction."
        },
        {
            question: "How do I find the right tutor for my needs?",
            answer: "Our platform features an advanced matching algorithm that connects you with tutors based on your specific requirements, subject matter, expertise level, and budget. You can also review ratings, testimonials, and sample teaching materials."
        },
        {
            question: "What are the platform fees?",
            answer: "Platform fees vary depending on the service type. Generally, we charge 5-10% for freelance projects and 15% for tutoring sessions. These fees help maintain platform quality and provide user protection services."
        },
        {
            question: "How can I resolve disputes with clients/freelancers?",
            answer: "We provide a dedicated dispute resolution center with professional mediators. Our team reviews each case thoroughly and ensures fair resolution based on project documentation and platform policies."
        },
        {
            question: "What types of projects can I post?",
            answer: "You can post virtually any legitimate project across various categories including development, design, writing, marketing, tutoring, and consulting. Each project must comply with our terms of service and community guidelines."
        }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    // Initialize AOS
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div class="sm:px-8 px-4 font-[sans-serif]">
            <div data-aos="fade-up" class="mb-12 sm:text-center text-start w-full">
                <h2 class="sm:text-2xl text-xl font-bold text-[#333]">Frequently asked questions</h2>
                <p class="sm:text-xl text-lgtext-gray-600 mt-6">
                    Find answers to common questions about iWorkFreelance.
                </p>
            </div>

            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div
                        key={index}
                        data-aos="fade-up"
                        data-aos-delay={index * 100} // Delay for staggered animation
                        className="bg-white rounded-lg shadow-md overflow-hidden"
                    >
                        <button
                            className=" px-6 py-4 justify-between focus:outline-none w-full text-base font-semibold text-left p-6 text-[#333] flex items-center"
                            onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                        >
                            <span className="mr-4">{faq.question}</span>
                            <span className="w-4 fill-current ml-auto shrink-0">
                                {activeIndex === index ? (
                                    <FiMinus className="w-6 h-6" />
                                ) : (
                                    <FiPlus className="w-6 h-6" />
                                )}
                            </span>
                        </button>

                        {activeIndex === index && (
                            <div className="px-6 pb-4" data-aos="fade-in">
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQSection;
